import {defineStore} from 'pinia'
import {pageSizes} from 'src/components/v1/order-list/config'
import type {GetOrdersPayload} from 'src/core/orders/orders.model'
import {computed, ref, type Ref} from 'vue'
import type {Tenant, TenantAllowedActions} from './core/tenants/tenants.model'
import {ModalTypeEnum} from './enums'
import {LocalStorageKeys, UserType} from './interfaces/storage'
import {TenantCMS} from './enums'

export const fetchOrdersPayloadInitialValue = {
  page: 1,
  pageSize: pageSizes[1],
}

export const defaultActions = {
  addCost: false,
  addDiscount: false,
  validateOrder: false,
  displayCMSLink: false,
  updateOrderStatus: false,
  waiveReturnFee: false,
  waiveDeliveryFee: false,
  declareReturn: false,
  changeReturnType: false,
}

export const shopifyActions = {
  addCost: true,
  addDiscount: true,
  validateOrder: true,
  displayCMSLink: true,
  updateOrderStatus: true,
  waiveReturnFee: true,
  waiveDeliveryFee: true,
  declareReturn: true,
  changeReturnType: true,
}

export const SFCCActions = {
  addCost: false,
  addDiscount: false,
  displayCMSLink: false,
  updateOrderStatus: true,
  validateOrder: false,
  waiveReturnFee: true,
  waiveDeliveryFee: false,
  declareReturn: true,
  changeReturnType: true,
}

export const N8NActions = {
  addCost: false,
  addDiscount: false,
  displayCMSLink: false,
  updateOrderStatus: true,
  validateOrder: false,
  waiveReturnFee: true,
  waiveDeliveryFee: false,
  declareReturn: false,
  changeReturnType: false,
}

export const useStore = defineStore('store', () => {
  const tenant = ref<Tenant | undefined>()
  const tenantAllowedActions = computed<TenantAllowedActions>(() => {
    if (!tenant.value) return defaultActions
    if (tenant.value.cms === TenantCMS.SFCC) return SFCCActions
    if (tenant.value.cms === TenantCMS.N8N) return N8NActions
    return shopifyActions
  })

  const userId = ref(localStorage.getItem(LocalStorageKeys.UserId) || 0)
  const isAdmin = ref(
    localStorage.getItem(LocalStorageKeys.UserType) === UserType.Admin,
  )
  const isUserLogged = ref(false)

  const latestActiveOrdersTab = ref<'orders' | 'returns' | undefined>()
  const fetchOrdersPayload: Ref<GetOrdersPayload> = ref({
    ...fetchOrdersPayloadInitialValue,
  })
  const openedModal: Ref<ModalTypeEnum | undefined> = ref()
  return {
    tenant,
    tenantAllowedActions,
    userId,
    isAdmin,
    isUserLogged,
    fetchOrdersPayload,
    latestActiveOrdersTab,
    openedModal,
  }
})
